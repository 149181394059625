
import { Vue, Component } from "vue-property-decorator";

Component.registerHooks(["beforeRouteEnter"]);

@Component
export default class UserTasks extends Vue {
    get hideTabs(): boolean {
        if (!this.$route.meta) return false;
        return !!this.$route.meta.hideTabs;
    }

    tab = 0;
}
